import React from 'react';
import {getRoutes} from "../../components/shared/routes";
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import ForestStreet2 from "../../components/sections/ForestStreet2/ForestStreet2";

const ForestStreetPage = () => {
    const routes = getRoutes();
    const pageTitle = routes.TraceabilityForestStreet.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <Breadcrumbs
                title={routes.TraceabilityForestStreet.pageTitle}
                parentTitles={[routes.Traceability.pageTitle]}
                parentRoutes={[routes.Traceability]}
            />

            <ForestStreet2/>
        </Layout>
    );
};

export default ForestStreetPage;
