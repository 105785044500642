import React from "react";
import css from './forestStreet2.module.scss';
import Video from "../../shared/Video/Video";
import Video1 from "./log_marking_by_dcode.mp4";
import Video2 from "./image_sourcing.mp4";
import Video3 from "./log_measurement.mp4";
import BackToTraceabilityButton from "../../shared/ButtonBackTo/BackToTraceabilityButton";
import AdditionalInformation from "../../shared/additionalInformation/additionalInformation";
import Footer from "../../shared/Footer/Footer";
import {getRoutes} from "../../shared/routes";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";
import VideoWithPreloadedImage from "../../shared/VideoWithPreloadedImage/VideoWithPreloadedImage";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_forest_street_2"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 420, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const ForestStreet2 = () => {
    const routes = getRoutes();
    const title = routes.TraceabilityForestStreet.pageTitle;
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    return (
        <div className={css.wrapper}>
            <div className={css.title}>{title}</div>
            <div className={css.imageSectionWrapper}>
                <div className={[css.imageWrapper, css.img1].join(' ')}>
                    <VideoWithPreloadedImage
                        videoSrcURL={Video1}
                        placeholderImage={'log_marking_by_dcode.jpg'}
                        placeholderImageAlt={'log marking based on Dcode'}
                        imagesFluid={imagesFluid}
                    />
                    <div className={css.imageText}>
                        log marking based on Dcode
                    </div>
                </div>
                <div className={[css.imageWrapper, css.img2].join(' ')}>
                    <VideoWithPreloadedImage
                        videoSrcURL={Video2}
                        placeholderImage={'image_sourcing.jpg'}
                        placeholderImageAlt={'image sourcing'}
                        imagesFluid={imagesFluid}
                    />
                    <div className={css.imageText}>
                        image sourcing by creating a single log face fingerprint signature record
                    </div>
                </div>
                <div className={[css.imageWrapper, css.img3].join(' ')}>
                    <VideoWithPreloadedImage
                        videoSrcURL={Video3}
                        placeholderImage={'log_measurement.jpg'}
                        placeholderImageAlt={'log measurement'}
                        imagesFluid={imagesFluid}
                    />
                    <div className={css.imageText}>
                        log measurement of single log faces diameters and length, lidar precision
                    </div>
                </div>
                <div className={[css.imageWrapper, css.img4].join(' ')}>
                    {renderImageByFileName(imagesFluid, 'encrypted_dcode_data_reader.png', 'encrypted dcode data reader')}
                    <div className={css.imageText}>
                        Encrypted Dcode data reader
                    </div>
                </div>
            </div>
            <BackToTraceabilityButton/>

            <AdditionalInformation/>

            <Footer/>

        </div>
    )
};

export default ForestStreet2;
